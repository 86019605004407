export const AdminLogHistoryFilterActionTypes = [
  {name: "create", value: "CREATE"},
  {name: "edit", value: "EDIT"},
  {name: "delete", value: "DELETE"},
];

export const AdminLogHistoryFilterLogTypes = [
  {name: "USER", value: "USER"},
  {name: "ADMIN", value: "ADMIN"},
  {name: "CONFIG", value: "CONFIG"},
  {name: "CODE", value: "CODE"},
  {name: "BLOG_CATEGORY", value: "BLOG_CATEGORY"},
  {name: "BLOG", value: "BLOG"},
  {name: "ADVICE", value: "ADVICE"},
  {name: "GOAL", value: "GOAL"},
  {name: "AVATAR", value: "AVATAR"},
  {name: "CHILD_WEEK", value: "CHILD_WEEK"},
];
