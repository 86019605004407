export function taskMapperListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      taskId: resource.objectId,
      adminSymptoms: resource.symptoms.map((item) => ({symptoms : item})),
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateTaskId(json) {
  return {
    data: {...json, id: json.objectId},
  };
}

export function fixCreateTaskId(params, json) {
  return {
    data: {...params.data, id: json.result.objectId},
  };
}

export function taskMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId, taskId: json.result.objectId,
      adminSymptoms: json.result.symptoms.map((item) => ({symptoms : item})),},
  };
}
