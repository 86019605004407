export function eventBannerListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateEventBannerId(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function fixCreateEventBannerId(params, json) {
  return {
    data: {...params.data, id: json.result.objectId},
  };
}

export function eventBannerMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId
    },
  };
}