export function fixGetManyAdminLogHistories(json) {
    return {
        data: json.map((resource) => ({
            ...resource,
            id: resource.objectId,
            adminLogHistoryId: resource.objectId,
        })),
    };
}

export function adminLogHistoryMapperListResponse(json, headers) {
    return {
        data: json.map((resource) => ({
            ...resource,
            id: resource.objectId,
            adminLogHistoryId: resource.objectId,
        })),
        total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
    };
}

export function adminLogHistoryMapperGetOneResponse(json) {
    return {
        data: {...json.result, id: json.result.objectId, adminLogHistoryId: json.result.objectId}
};
}