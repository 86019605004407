import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  RichTextField,
  DateInput,
  SelectInput,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
  ImageField,
  UrlField,
  ImageInput,
  ArrayInput,
  SimpleFormIterator,
  FunctionField,
  BooleanField,
  BooleanInput,
  ArrayField, NumberInput, NumberField
} from "react-admin";
import CreateView from "../../components/CreateView";
import EditView from "../../components/EditView";
import ShowView from "../../components/ShowView";
import {AdviceFilterConditions, AdviceFilterTypes, AdviceTypes} from "./adviceConstants";
import Emoji from "../../components/Emoji";
import {Markets, MarketsFilter} from "../../constants/choices";

const AdviceFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Type"
      choices={AdviceFilterTypes}
      optionText={"name"}
      optionValue={"value"}
      source={"type"}
    />
    <SelectInput
      label="Market"
      choices={MarketsFilter}
      optionText={"name"}
      optionValue={"value"}
      source={"markets"}
    />
    <SelectInput
      label="Pin"
      choices={AdviceFilterConditions}
      optionText={"name"}
      optionValue={"value"}
      source={"isPin"}
    />
    <SelectInput
      label="Ads"
      choices={AdviceFilterConditions}
      optionText={"name"}
      optionValue={"value"}
      source={"isAdvertise"}
    />
    <SelectInput
      label="Daily"
      choices={AdviceFilterConditions}
      optionText={"name"}
      optionValue={"value"}
      source={"isDaily"}
    />
    <SelectInput
      label="Hidden"
      choices={AdviceFilterConditions}
      optionText={"name"}
      optionValue={"value"}
      source={"isHidden"}
    />
    <NumberInput
      label="Version"
      source={"version"}
    />
  </Filter>
);

export const AdviceList = (props) => (
  <List {...props} filters={<AdviceFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <TextField source="title"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <FunctionField
        label="Content"
        render={record => `${record.text.substr(0, 150)} `}
      />;
      <TextField source="urlTitle"/>
      <UrlField source="url"/>
      <ImageField source="bannerImage"/>
      <BooleanField source="isPin"/>
      <BooleanField source="isAdvertise"/>
      <BooleanField source="isDaily"/>
      <BooleanField source="isHidden"/>
      <DateField showTime={true} source="expirationDate"/>
      <TextField source="type"/>
      <ArrayField source="adminMarkets">
        <Datagrid>
          <TextField source="markets"/>
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);

export const AdviceCreate = (props) => {
  return (
    <CreateView {...props} perPage={10}>
      <TextInput source="title" validate={required()}/>
      <SelectInput source="type" validate={required()} choices={AdviceTypes}/>
      <TextInput source="urlTitle"/>
      <TextInput source="url"/>
      <Emoji label="Description" source="text" validate={required()}/>
      <BooleanInput source="isPin" defaultValue={false}/>
      <BooleanInput source="isAdvertise" defaultValue={false}/>
      <BooleanInput source="isDaily" defaultValue={false}/>
      <BooleanInput source="isHidden" defaultValue={false}/>
      <NumberInput source="version"/>
      <DateInput
        source="expirationDate"
        label={"Expiration date"}
      />
      <ImageInput source="pictures" label="Image" accept="image/*">
        <ImageField source="src" title="imageUrl"/>
      </ImageInput>
      <ArrayInput source="adminMarkets">
        <SimpleFormIterator>
          <SelectInput source="markets" choices={Markets}/>
        </SimpleFormIterator>
      </ArrayInput>
    </CreateView>
  );
}


export const AdviceEdit = (props) => {
  return (
    <EditView {...props}>
      <TextInput source="title" validate={required()}/>
      <SelectInput source="type" validate={required()} choices={AdviceTypes}/>
      <TextInput source="urlTitle"/>
      <TextInput source="url"/>
      <Emoji label="Description" source="text" validate={required()}/>
      <BooleanInput source="isPin" defaultValue={false}/>
      <BooleanInput source="isAdvertise" defaultValue={false}/>
      <BooleanInput source="isDaily" defaultValue={false}/>
      <BooleanInput source="isHidden" defaultValue={false}/>
      <NumberInput source="version"/>

      <DateInput
        source="expirationDate"
        label={"Expiration date"}
      />

      <ImageInput source="pictures" label="Image" accept="image/*">
        <ImageField source="src" title="imageUrl"/>
      </ImageInput>
      <TextInput source="bannerImage"/>
      <ArrayInput source="adminMarkets">
        <SimpleFormIterator>
          <SelectInput source="markets" choices={Markets}/>
        </SimpleFormIterator>
      </ArrayInput>
    </EditView>
  );
}

export const AdviceShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="objectId"/>
      <TextField source="title"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <RichTextField source="text"/>
      <BooleanField source="isPin"/>
      <BooleanField source="isAdvertise"/>
      <BooleanField source="isDaily"/>
      <BooleanField source="isHidden"/>
      <DateField showTime={true} source="expirationDate"/>
      <TextField source="urlTitle"/>
      <UrlField source="url"/>
      <ImageField source="bannerImage"/>
      <TextField source="typeName"/>
      <NumberField source="version"/>
      <ArrayField source="adminMarkets">
        <Datagrid>
          <TextField source="markets"/>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </ShowView>
);
