import {AdviceTypes} from "./adviceConstants";

export function adviceListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      type: getType(resource.type),
      adminMarkets: resource.markets.map((item) => ({markets : item})),
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateAdviceId(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function fixCreateAdviceId(params, json) {
  return {
    data: {...params.data, id: json.result.objectId},
  };
}

export function adviceMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId, typeName: getType(json.result.type),
      adminMarkets: json.result.markets.map((item) => ({markets : item})),
    },
  };
}


export function getType(type) {
  return AdviceTypes[type-1].name;
}