import React from "react";
import {
    Datagrid,
    DateField,
    Filter,
    List,
    RichTextField,
    SelectInput,
    SimpleShowLayout,
    TextField,
    required,
    ArrayInput,
    SimpleFormIterator,
    ArrayField,
} from "react-admin";
import CreateView from "../../components/CreateView";
import EditView from "../../components/EditView";
import ShowView from "../../components/ShowView";
import Emoji from "../../components/Emoji";
import { Markets, MarketsFilter } from "../../constants/choices";

const TermsConditionFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Market"
      choices={MarketsFilter}
      optionText={"name"}
      optionValue={"value"}
      source={"markets"}
    />
  </Filter>
);

export const TermsConditionList = (props) => (
  <List {...props} filters={<TermsConditionFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <ArrayField source="adminMarkets">
        <Datagrid>
          <TextField source="markets" />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);

export const TermsConditionCreate = (props) => (
    <CreateView {...props} perPage={10}>
        <Emoji label="Description" source="text" validate={required()}/>
        <ArrayInput source="adminMarkets">
          <SimpleFormIterator>
            <SelectInput source="markets" choices={Markets} />
          </SimpleFormIterator>
        </ArrayInput>
    </CreateView>
);


export const TermsConditionEdit = (props) => {
    return (
    <EditView {...props}>
        <Emoji label="Description" source="text" validate={required()}/>
        <ArrayInput source="adminMarkets">
          <SimpleFormIterator>
            <SelectInput source="markets" choices={Markets} />
          </SimpleFormIterator>
        </ArrayInput>
    </EditView>
    );
}

export const TermsConditionShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="objectId"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <RichTextField source="text"/>
      <ArrayField source="adminMarkets">
        <Datagrid>
          <TextField source="markets" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </ShowView>
);
