import {stringify} from "query-string";
import {SERVER} from "../constants";
import {MAP_URL} from "../constants/urls";
import {HttpClient} from "../utils/requests";
import {blogCategoryMapperListResponse} from "../panels/blogCategory/blogCategoryMapper";
import {adviceListResponse} from "../panels/advice/adviceMapper";
import {blogMapperListResponse} from "../panels/blog/blogMapper";
import {userMapperListResponse} from "../panels/user/userMapper";
import {configMapperListResponse} from "../panels/config/configMapper";
import {periodMapperListResponse} from "../panels/period/periodMapper";
import {codesMapperListResponse} from "../panels/codes/codeMapper";
import {adminLogHistoryMapperListResponse} from "../panels/adminLogHistory/adminLogHistoryMapper";
import {avatarMapperListResponse} from "../panels/avatar/avatarMapper";
import {childWeekMapperListResponse} from "../panels/childWeek/childWeekMapper";
import {marketLoginListResponse} from "../panels/marketLogin/marketLoginMapper";
import {welcomeModalListResponse} from "../panels/welcomeModal/welcomeModalMapper";
import {termsConditionListResponse} from "../panels/termsCondition/termsConditionMapper";
import {eventBannerListResponse} from "../panels/eventBanner/eventBannerMapper";
import {taskMapperListResponse} from '../panels/task/taskMapper'
import {taskCategoryMapperListResponse} from '../panels/taskCategory/taskCategoryMapper'

export function getList(resource, params) {
  const {page, perPage} = params.pagination;
  const {field, order} = params.sort;
  let sortKey = field;
  let sortOrder = order;
  if (
    [
      "advice",
      "user",
      "periods",
      "codes",
      "blog",
      "blogCategory",
      "task",
      "taskCategory",
      "adminUsers",
      "adminLogHistory",
      "avatar",
      "userVariant",
      "childWeek",
      "marketLogin",
      "welcomeModal",
      "termsCondition",
      "eventBanner",
    ].includes(resource) &&
    sortKey === "id"
  ) {
    sortKey = "objectId";
  }
  const query = {
    range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
    sort: JSON.stringify([sortKey, sortOrder]),
    from: JSON.stringify((page - 1) * perPage),
    to: JSON.stringify(page * perPage - 1),
    filter: JSON.stringify(params.filter),
    phoneNumber: params.filter.phoneNumber,
  };
  const url = `${SERVER}/${MAP_URL[resource]}?${stringify(query)}`;

  return HttpClient(url).then(({headers, json}) => {
    if (resource === "advice") {
      return adviceListResponse(json, headers);
    } else if (resource === "blogCategory") {
      return blogCategoryMapperListResponse(json, headers);
    } else if (resource === "blog") {
      return blogMapperListResponse(json, headers);
    } else if (resource === "taskCategory") {
      return taskCategoryMapperListResponse(json, headers);
    } else if (resource === "task") {
      return taskMapperListResponse(json, headers);
    } else if (resource === "user") {
      return userMapperListResponse(json, headers);
    } else if (resource === "config") {
      return configMapperListResponse(json, headers);
    } else if (resource === "periods") {
      return periodMapperListResponse(json, headers);
    } else if (resource === "codes") {
      return codesMapperListResponse(json, headers);
    } else if (resource === "adminLogHistory") {
      return adminLogHistoryMapperListResponse(json, headers);
    } else if (resource === "avatar") {
      return avatarMapperListResponse(json, headers);
    } else if (resource === "childWeek") {
      return childWeekMapperListResponse(json, headers);
    } else if (resource === "marketLogin") {
      return marketLoginListResponse(json, headers);
    } else if (resource === "termsCondition") {
        return termsConditionListResponse(json, headers);
    } else if (resource === "welcomeModal") {
        return welcomeModalListResponse(json, headers);
    } else if (resource === "eventBanner") {
        return eventBannerListResponse(json, headers);
    }

    return {
      data: json.map((resource) => ({
        ...resource,
        id: resource.phoneNumber,
      })),
      total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
    };
  });
}
