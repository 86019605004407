import React from "react";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import PeopleIcon from "@material-ui/icons/People";

export const MenuCategories = {
  admin: {
    id: "admin",
    name: "Admins",
    icon: <VerifiedUserIcon />,
  },
  content: {
    id: "content",
    name: "Contents",
    icon: <SpeakerNotesIcon />,
  },
  user: {
    id: "user",
    name: "Users",
    icon: <PeopleIcon />,
  },
  blog: {
    id: "blog",
    name: "Blog",
    icon: <SpeakerNotesIcon />,
  },
  task: {
    id: "task",
    name: "Task",
    icon: <SpeakerNotesIcon />,
  },
};

export const MenuIsOpenState = {
  adminUsers: false,
  advice: false,
  blog: false,
  blogCategory: false,
  task: false,
  taskCategory: false,
  periods: false,
  config: false,
  user: false,
};
