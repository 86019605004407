import {SERVER} from "../constants";
import {stringify} from "query-string";
import {MAP_URL} from "../constants/urls";
import {HttpClient} from "../utils/requests";
import {fixGetManyBlogCategories} from "../panels/blogCategory/blogCategoryMapper";
import {fixGetManyUsers} from "../panels/user/userMapper";
import {fixGetManyAdminLogHistories} from "../panels/adminLogHistory/adminLogHistoryMapper";
import {fixGetManyAdmins} from "../panels/adminUsers/adminUsersMapper";
import {fixGetManyAvatars} from "../panels/avatar/avatarMapper";
import {fixGetManyTaskCategories} from '../panels/taskCategory/taskCategoryMapper'

export function getMany(resource, params) {
  let ids = [];
  if (params.ids) {
    ids = params.ids;
  }
  const query = {
    filter: JSON.stringify({id: params.objectId, objectId: ids}),
  };
  const url = `${SERVER}/${MAP_URL[resource]}?${stringify(query)}`;
  return HttpClient(url).then(({json}) => {
    if (resource === "blogCategory") {
      return fixGetManyBlogCategories(json);
    } else if (resource === "taskCategory") {
      return fixGetManyTaskCategories(json);
    } else if (resource === "user") {
      return fixGetManyUsers(json);
    } else if (resource === "adminLogHistory") {
      return fixGetManyAdminLogHistories(json);
    } else if (resource === "adminUsers") {
      return fixGetManyAdmins(json);
    } else if (resource === "avatar") {
      return fixGetManyAvatars(json);
    }

    return {
      data: {...json, id: json.objectId},
    };
  });
}
