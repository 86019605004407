import {SERVER} from "../constants";
import {MAP_URL} from "../constants/urls";
import {HttpClient} from "../utils/requests";
import {adviceMapperGetOneResponse} from "../panels/advice/adviceMapper";
import {blogMapperGetOneResponse} from "../panels/blog/blogMapper";
import {blogCategoryMapperGetOneResponse} from "../panels/blogCategory/blogCategoryMapper";
import {fixConfigId} from "../panels/config/configMapper";
import {userMapperGetOneResponse} from "../panels/user/userMapper";
import {adminUsersMapperGetOneResponse} from "../panels/adminUsers/adminUsersMapper";
import {adminLogHistoryMapperGetOneResponse} from "../panels/adminLogHistory/adminLogHistoryMapper";
import {avatarMapperGetOneResponse} from "../panels/avatar/avatarMapper";
import {childWeekMapperGetOneResponse} from "../panels/childWeek/childWeekMapper";
import {marketLoginMapperGetOneResponse} from "../panels/marketLogin/marketLoginMapper";
import {welcomeModalMapperGetOneResponse} from "../panels/welcomeModal/welcomeModalMapper";
import {termsConditionMapperGetOneResponse} from "../panels/termsCondition/termsConditionMapper";
import {eventBannerMapperGetOneResponse} from "../panels/eventBanner/eventBannerMapper";

export function getOne(resource, params) {
  return HttpClient(`${SERVER}/${MAP_URL[resource]}/${params.id}`).then(({json}) => {
      if (resource === 'advice') {
        return adviceMapperGetOneResponse(json);
      } else if (resource === 'blogCategory') {
        return blogCategoryMapperGetOneResponse(json);
      } else if (resource === 'blog') {
        return blogMapperGetOneResponse(json);
      } else if (resource === 'config') {
        return fixConfigId(params, json);
      } else if (resource === 'user') {
        return userMapperGetOneResponse(params, json);
      } else if (resource === 'adminUsers') {
        return adminUsersMapperGetOneResponse(json);
      } else if (resource === 'adminLogHistory') {
        return adminLogHistoryMapperGetOneResponse(json);
      } else if (resource === "avatar") {
        return avatarMapperGetOneResponse(json);
      } else if (resource === "childWeek") {
        return childWeekMapperGetOneResponse(json);
      } else if (resource === "marketLogin") {
        return marketLoginMapperGetOneResponse(json);
      } else if (resource === "welcomeModal") {
          return welcomeModalMapperGetOneResponse(json);
      } else if (resource === "termsCondition") {
          return termsConditionMapperGetOneResponse(json);
      } else if (resource === "eventBanner") {
          return eventBannerMapperGetOneResponse(json);
      }

      return {data: {...json.result, id: json.result.objectId}};
    }
  );
}
