import {LoginTypes} from "./marketLoginConstants";

export function marketLoginListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      adminLoginTypes: resource.loginTypes.map((item) => ({loginTypes : getLoginType(item)})),
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateMarketLoginId(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function fixCreateMarketLoginId(params, json) {
  return {
    data: {...params.data, id: json.result.objectId},
  };
}

export function marketLoginMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId,
      adminLoginTypesShow: json.result.loginTypes.map((item) => ({loginTypes : getLoginType(item)})),
      adminLoginTypes: json.result.loginTypes.map((item) => ({loginTypes : item})),
    },
  };
}

export function getLoginType(type) {
  return LoginTypes[type-1].name;
}