import React from "react";
import {
    Datagrid, DateField,
    List,
    TextField,
    TextInput,
    SelectInput,
    Filter, ReferenceField, SimpleShowLayout,
} from "react-admin";
import ShowView from "../../components/ShowView";
import {AdminLogHistoryFilterActionTypes, AdminLogHistoryFilterLogTypes} from "./adminLogHistoryConstant";

const AdminLogHistoryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="objectId"/>
    <TextInput label="User ID" source="userId"/>
    <SelectInput
      label="Operation"
      choices={AdminLogHistoryFilterActionTypes}
      optionText={"name"}
      optionValue={"value"}
      source={"action"}
      alwaysOn
    />
      <SelectInput
          label="Type"
          choices={AdminLogHistoryFilterLogTypes}
          optionText={"name"}
          optionValue={"value"}
          source={"logType"}
          alwaysOn
      />
  </Filter>
);


export const AdminLogHistoryList = (props) => (
  <List {...props} filters={<AdminLogHistoryFilter/>} >
    <Datagrid rowClick={"show"}>
      <TextField source="objectId"/>
        <ReferenceField link="show" label="User" source="userId" reference="user" >
            <TextField source="name"/>
        </ReferenceField>
        <TextField source="phoneNumber"/>
        <TextField source="logType"/>
        <TextField source="action"/>
        <DateField showTime={true} source="createdAt"/>
    </Datagrid>
  </List>
);

export const AdminLogHistoryShow = (props) => (
    <ShowView {...props} noDel={true} noEdit={true}>
    <SimpleShowLayout>
        <TextField source="objectId"/>
        <ReferenceField link="show" label="User" source="userId" reference="user" >
            <TextField source="name"/>
        </ReferenceField>
        <TextField source="phoneNumber"/>
        <TextField source="logType"/>
        <TextField source="action"/>
        <TextField source="previousData"/>
        <TextField source="nextData"/>
        <DateField  showTime={true} source="createdAt"/>
    </SimpleShowLayout>
    </ShowView>
);
