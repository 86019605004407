export const MAP_URL = {
  login: "admin/login",
  advice: "admin/advice",
  adminUsers: "admin/adminUsers",
  user: "admin/user",
  blogCategory: "admin/blogCategory",
  blog: "admin/blog",
  config: "admin/config",
  periods: "admin/periods",
  codes: "admin/codes",
  sheroDeepLink : 'shero://messageNotification/',
  adminLogHistory: "admin/logHistory",
  avatar: "admin/avatar",
  childWeek: "admin/childWeek",
  marketLogin: "admin/marketLogin",
  welcomeModal: "admin/welcomeModal",
  termsCondition: "admin/terms",
  eventBanner: "admin/eventBanner",
};
