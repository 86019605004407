export const Roles = [
  {id: "SUPER_ADMIN", name: "Super Admin"},
  {id: "USERS_ADMIN", name: "Normal admin"},
  {id: "MARKETING_ADMIN", name: "Marketing admin"},
];

export const ChatRoles = [
  { id: 'NORMAL', name: 'NORMAL' },
  { id: 'CRM', name: 'CRM' },
  { id: 'SUPER_ADMIN', name: 'SUPER_ADMIN' },
];

export const Markets = [
  { id: 'GOOGLE_PLAY', name: 'GOOGLE_PLAY' },
  { id: 'IOS_APP_STORE', name: 'IOS_APP_STORE' },
  { id: 'PWA', name: 'PWA' },
];

export const MarketsFilter = [
  { name: 'GOOGLE_PLAY', value: 'GOOGLE_PLAY' },
  { name: 'IOS_APP_STORE', value: 'IOS_APP_STORE' },
  { name: 'PWA', value: 'PWA' },
];

export const Symptoms = [
  { id: 'PAIN', name: 'PAIN' },
];

export const SymptomsFilter = [
  { name: 'PAIN', value: 'PAIN' },
];
