import {SERVER} from "../constants";
import {MAP_URL} from "../constants/urls";
import {FileUploader, HttpClient} from "../utils/requests";
import {fixUpdateBlogCategoryId} from "../panels/blogCategory/blogCategoryMapper";
import {fixUpdateAdviceId} from "../panels/advice/adviceMapper";
import {fixUpdateBlogId} from "../panels/blog/blogMapper";
import {fixUpdateConfigId} from "../panels/config/configMapper";
import {fixUpdateAvatarId} from "../panels/avatar/avatarMapper";
import {fixUpdateChildWeekId} from "../panels/childWeek/childWeekMapper";
import {fixUpdateMarketLoginId} from "../panels/marketLogin/marketLoginMapper";
import {fixUpdateWelcomeModalId} from "../panels/welcomeModal/welcomeModalMapper";
import {fixUpdateTermsConditionId} from "../panels/termsCondition/termsConditionMapper";
import {fixUpdateEventBannerId} from "../panels/eventBanner/eventBannerMapper";
import {fixUpdateTaskId} from '../panels/task/taskMapper'
import {fixUpdateTaskCategoryId} from '../panels/taskCategory/taskCategoryMapper'

export async function update(resource, params) {

  if (resource === 'advice' && params.data.pictures) {
    await FileUploader(resource, params, params.data.pictures, 'advice');
    params.data['bannerImage'] = params.data.url;
    delete params.data.url;
    delete params.data.pictures;
  }

  if (['blog', 'blogCategory', 'task', 'taskCategory'].includes(resource) && params.data.pictures) {
    await FileUploader(resource, params, params.data.pictures, resource);
    params.data['icon'] = params.data.url;
    delete params.data.url;
    delete params.data.pictures;
  }

  if (resource === 'user' && params.data.files) {
    await FileUploader(resource, params, params.data.files);
    params.data['avatarUrl'] = params.data.url;
    delete params.data.url;
  }

  if (resource === 'avatar' && params.data.avatar) {
    await FileUploader(resource, params, params.data.avatar);
    params.data['avatarUrl'] = params.data.url;
    delete params.data.url;
    delete params.data.avatar;
  }

  if (resource === 'eventBanner' && params.data.pictures) {
    await FileUploader(resource, params, params.data.pictures);
    params.data['image'] = params.data.url;
    delete params.data.url;
    delete params.data.pictures;
  }

  if (resource === 'childWeek' && params.data.image) {
    await FileUploader(resource, params, params.data.image);
    params.data['imageUrl'] = params.data.url;
    delete params.data.url;
    delete params.data.image;
  }

  if (['advice', 'blog', 'blogCategory', 'childWeek'].includes(resource)) {
    params.data.markets = params.data.adminMarkets?.map(item => item.markets);
  }

  if (['task'].includes(resource)) {
    params.data.symptoms = params.data.adminSymptoms?.map(item => item.symptoms);
  }

  if (['marketLogin'].includes(resource)) {
    params.data.loginTypes = params.data.adminLoginTypes?.map(item => item.loginTypes);
  }

  if (resource === 'advice') {
    params.data.expirationDate = params.data.expirationDate && new Date(params.data.expirationDate).getTime();
  }

  let url = `${SERVER}/${MAP_URL[resource]}`;
  if (['adminUsers', 'config', 'user'].includes(resource)) {
    url = url + `/${params.id}`;
  }
  if (['blogCategory', 'taskCategory'].includes(resource)) {
    let x = {...params.data, categoryId: params.data.objectId};
    params.data = x;
  }
  return HttpClient(url, {
    method: "PUT",
    body: JSON.stringify(params.data),
  }).then(({json}) => {
      if (resource === 'advice') {
        return fixUpdateAdviceId(json);
      } else if (resource === 'blogCategory') {
        return fixUpdateBlogCategoryId(json);
      } else if (resource === 'blog') {
        return fixUpdateBlogId(json);
      } else if (resource === 'taskCategory') {
        return fixUpdateTaskCategoryId(json);
      } else if (resource === 'task') {
        return fixUpdateTaskId(json);
      } else if (resource === 'config') {
        return fixUpdateConfigId(json);
      } else if (resource === 'avatar') {
        return fixUpdateAvatarId(json);
      } else if (resource === 'childWeek') {
        return fixUpdateChildWeekId(json);
      } else if (resource === 'marketLogin') {
        return fixUpdateMarketLoginId(json);
      } else if (resource === 'welcomeModal') {
        return fixUpdateWelcomeModalId(json);
      } else if (resource === 'termsCondition') {
        return fixUpdateTermsConditionId(json);
      } else if (resource === "eventBanner") {
        return fixUpdateEventBannerId(json);
      }

      return {
        data: {...json, id: json.phoneNumber},
      };
    }
  );
}
