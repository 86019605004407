import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SelectInput,
  SimpleShowLayout,
  TextField,
  required,
  ArrayInput,
  SimpleFormIterator,
  ArrayField
} from "react-admin";
import CreateView from "../../components/CreateView";
import EditView from "../../components/EditView";
import ShowView from "../../components/ShowView";
import { Markets, MarketsFilter } from "../../constants/choices";
import {LoginTypes} from "./marketLoginConstants";

const MarketLoginFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Market"
      choices={MarketsFilter}
      optionText={"name"}
      optionValue={"value"}
      source={"market"}
      alwaysOn
    />
  </Filter>
);

export const MarketLoginList = (props) => (
  <List {...props} filters={<MarketLoginFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
      <TextField source="market"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <ArrayField source="adminLoginTypes">
        <Datagrid>
          <TextField source="loginTypes"/>
        </Datagrid>
      </ArrayField>
    </Datagrid>
  </List>
);

export const MarketLoginCreate = (props) => (
    <CreateView {...props} perPage={10}>
    <SelectInput source="market" choices={Markets} validate={required()} />
    <ArrayInput source="adminLoginTypes">
      <SimpleFormIterator>
          <SelectInput source="loginTypes" choices={LoginTypes} validate={required()} />
      </SimpleFormIterator>
    </ArrayInput>
    </CreateView>
);


export const MarketLoginEdit = (props) => {
    return (
    <EditView {...props}>
        <SelectInput source="market" choices={Markets} validate={required()} />
        <ArrayInput source="adminLoginTypes">
          <SimpleFormIterator>
            <SelectInput source="loginTypes" choices={LoginTypes} />
          </SimpleFormIterator>
        </ArrayInput>
    </EditView>
  );
}

export const MarketLoginShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="objectId"/>
      <TextField source="market"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
      <ArrayField source="adminLoginTypesShow">
        <Datagrid>
          <TextField source="loginTypes" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </ShowView>
);
