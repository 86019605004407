import React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
} from "react-admin";
import CreateView from "../../components/CreateView";
import EditView from "../../components/EditView";
import ShowView from "../../components/ShowView";


const WelcomeModalFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Version"
      source={"version"}
      alwaysOn
    />
  </Filter>
);

export const WelcomeModalList = (props) => (
  <List {...props} filters={<WelcomeModalFilter/>}>
    <Datagrid rowClick="show">
      <TextField source="objectId"/>
        <TextField source="text"/>
        <TextField source="deepLink"/>
        <TextField source="imageLink"/>
        <TextField source="version"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
    </Datagrid>
  </List>
);

export const WelcomeModalCreate = (props) => (
    <CreateView {...props} perPage={10}>
        <TextInput source="text" validate={required()}/>
        <TextInput source="version" validate={required()}/>
        <TextInput source="deepLink"/>
        <TextInput source="imageLink"/>
    </CreateView>
);


export const WelcomeModalEdit = (props) => {
    return (
    <EditView {...props}>
        <TextInput source="text" validate={required()}/>
        <TextInput source="version" validate={required()}/>
        <TextInput source="deepLink"/>
        <TextInput source="imageLink"/>
    </EditView>
  );
}

export const WelcomeModalShow = (props) => (
  <ShowView {...props}>
    <SimpleShowLayout>
      <TextField source="objectId"/>
        <TextField source="text"/>
        <TextField source="version"/>
        <TextField source="deepLink"/>
        <TextField source="imageLink"/>
      <DateField showTime={true} source="createdAt"/>
      <DateField showTime={true} source="updatedAt"/>
    </SimpleShowLayout>
  </ShowView>
);
