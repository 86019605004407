export function taskCategoryMapperListResponse(json, headers) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      categoryId: resource.objectId,
    })),
    total: headers.get("X-Total-Count") ? parseInt(headers.get("X-Total-Count").split("/").pop(), 10) : 1000,
  };
}

export function fixUpdateTaskCategoryId(json) {
  return {
    data: {...json.result, id: json.result.objectId},
  };
}

export function fixGetManyTaskCategories(json) {
  return {
    data: json.map((resource) => ({
      ...resource,
      id: resource.objectId,
      categoryId: resource.objectId,
    })),
  };
}

export function fixCreateTaskCategoryId(params, json) {
  return {
    data: {...json.result, id: json.result.objectId, categoryId: json.result.objectId},
  };
}

export function taskCategoryMapperGetOneResponse(json) {
  return {
    data: {...json.result, id: json.result.objectId}
  };
}
